/*---------------------
    Tab Styles  
-----------------------*/
.tab-button-panel {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.rn-default-tab {
  // Tab Button
  .tab-button {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    .react-tabs__tab {
      padding: 20px 44px;
      margin: 0;

      @media #{$lg-layout} {
        padding: 20px 24px;
      }

      @media #{$md-layout} {
        padding: 20px 24px;
      }

      @media #{$sm-layout} {
        padding: 20px 24px;
      }

      &.react-tabs__tab--selected {
        background: var(--color-lessdark);
      }
    }
    .rn-tab-button {
      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0 none;
        color: var(--color-white);
      }
    }
  }

  // Tab Content
  .rn-tab-content {
    background: var(--color-lessdark);
    padding: 40px 40px;
    @media #{$sm-layout} {
      padding: 30px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.style-two {
    .tab-button {
      margin-bottom: 30px;

      .react-tabs__tab {
        border-radius: var(--radius-small);
      }
    }
    .rn-tab-content {
      border-radius: var(--radius-small);
    }
  }
}

.single-list {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.single-list label {
  font-weight: bold;
  flex: 1;
  text-align: left;
}

.single-list span {
  flex: 3;
  text-align: left;
}

.single-list-two {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.single-list-two label {
  font-weight: bold;
  flex: 1;
  text-align: left;
}

.single-list-two span {
  flex: 2;
  text-align: left;
}
